import React, { Suspense } from 'react';

import { Outlet } from 'react-router-dom';

import { AppWrapper } from '@/components/AppWrapper';
import Header from '@/components/Header';
import { Sidebar } from '@/components/Sidebar';

import { GameZoneTemplateContextProvider } from '@/context/GameZoneTemplate.context';
import { LocalStorageProvider } from '@/context/localStorage.context';
import { ProgressContextProvider } from '@/context/progress.context';
import { SidebarContextProvider } from '@/context/sidebar.context';
import { UserContextProvider } from '@/context/user.context';

import { PageLoader } from '@/elements/Loaders/page-loader';

import { IS_HEADER_HIDDEN } from '@/utils/constants';

const AppLayout: React.FC = () => {
  return (
    <LocalStorageProvider>
      <ProgressContextProvider>
        <GameZoneTemplateContextProvider>
          <SidebarContextProvider>
            <UserContextProvider>
              <AppWrapper>
                {!IS_HEADER_HIDDEN && <Header />}
                <Sidebar />
                <Suspense fallback={<PageLoader />}>
                  <Outlet />
                </Suspense>
              </AppWrapper>
            </UserContextProvider>
          </SidebarContextProvider>
        </GameZoneTemplateContextProvider>
      </ProgressContextProvider>
    </LocalStorageProvider>
  );
};

export default AppLayout;
