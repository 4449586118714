import { useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

import { SearchParamsEnum } from '@/enum/searchParams.enum';
import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';

export const useRedirectUrl = () => {
  const [searchParams] = useSearchParams();
  const { setItem: setRedirectUrl } = useLocalStorage<string>(
    StorageKeysEnum.redirectURL,
  );

  const redirectURL = searchParams.get(SearchParamsEnum.redirectURL);

  useEffect(() => {
    if (redirectURL) {
      setRedirectUrl(redirectURL);
    }
  }, [redirectURL]);
};
