export enum RoutesEnum {
  HOME = '/',
  FORGOT = '/forgot',
  GAMEZONE = '/gamezone',
  LOGIN = '/login',
  PROFILE = '/profile',
  REDIRECT_CALLBACK = '/redirect-callback',
  REGISTRATION = '/registration',
  RESET = '/reset',
  SSOENTRY = '/sso/entry',
  UNSUB = '/unsubscribe',
  USERNAME_UPDATE = '/username-update',
  MY_HISTORY = '/my-history',
}
