import { useTranslation } from 'react-i18next';

import { tKeys } from '@/localization';

import { ReactComponent as DashboardIcon } from '@/assets/icons/dashboard.svg';
import { ReactComponent as ProfileIcon } from '@/assets/icons/profile.svg';
import { ReactComponent as TournamentsIcon } from '@/assets/icons/tournaments.svg';

import { RoutesEnum } from '@/enum/routes.enum';

import { HIDE_PROFILE } from '@/utils/constants';

export const useNavigations = () => {
  const { t } = useTranslation();

  const navigations = [
    { icon: DashboardIcon, label: t(tKeys.gamezone), link: RoutesEnum.HOME },
    { icon: ProfileIcon, label: t(tKeys.profile), link: RoutesEnum.PROFILE },
    {
      icon: TournamentsIcon,
      label: t(tKeys.history),
      link: RoutesEnum.MY_HISTORY,
    },
  ];

  return !HIDE_PROFILE
    ? navigations
    : navigations.filter(({ link }) => link !== RoutesEnum.PROFILE);
};
