const colors = {
  absoluteDark: '#000000',
  defaultGray: '#666666',

  toast: {
    success: '#009656',
    error: '#E22F2F',
    text: '#FFFFFF',
  },

  appBg: {
    primaryBg: '#292935',
    secondaryBg: '#FFFFFF',
  },
  header: {
    border: 'rgba(255,255,255, 0.3)',
    logo: '#FFFFFF',
    text: '#FFFFFF',
    primaryBg: '#292935',
    secondaryBg: '#A01313',
  },
  navbar: {
    bg: '#292935',
    text: '#FFFFFF',
    selected: '#F73535',
  },
  contestCard: {
    bg: '#FFFFFF',
    text: '#E22F2F',
    gradient: 'linear-gradient(90deg, rgba(0, 150, 86, 0) 0%, #009656 100%)',
  },
  cardSmall: {
    bg: '#292935',
    descriptionBg: '#F73535',
    text: '#FFFFFF',
    buttonBg: '#FFFFFF',
    buttonBgActive: '#FFFFFF',
    gradient: 'linear-gradient(90deg, rgba(0, 150, 86, 0) 0%, #009656 100%)',
    title: '#FFFFFF',
    description: '#FFFFFF',
  },
  tile: {
    bg: 'linear-gradient(251deg, #1CFF47 -12.7%, #25BD95 103.67%)',
    text: '#111111',
  },
  contestTile: {
    bg: '#33CFFF',
    divider: '#259BC0',
    infoBg: 'linear-gradient(180deg, #37373D 0%, #19191C 100%)',
    infoText: '#FFFFFF',
    text: '#111111',
  },
  userContestTile: {
    bg: '#3B3B54',
    text: '#FFFFFF',
    winBg: 'linear-gradient(251deg, #1CFF47 -12.7%, #25BD95 103.67%)',
    winText: '#292935',
  },
  timer: {
    bg: '#19191C',
    liveBg: 'linear-gradient(99deg, #F73535 13.3%, #F2B60F 86.79%)',
    text: '#FFFFFF',
    imageActive: '#E22F2F',
  },
  section: {
    title: '#FFFFFF',
    leftImage: {
      title: '#E22F2F',
      subtitle: '#E22F2F',
      description: '#E22F2F',
    },
  },
  carousel: {
    bullet: '#3B3B54',
    bulletActive: '#F73535',
  },
  langSwitcher: {
    bg: '#292935',
    icon: '#F73535',
    border: '#A01313',
    text: '#FFFFFF',
  },
  input: {
    bg: '#3B3B54',
    border: '#88B3FF',
    text: '#FFFFFF',
    placeholder: '#8A8AB2',
    invalid: '#E22F2F',
    cancel: '#D9D9D9',
    hint: '#D9D9D9',
    success: '#009656',
    eye: '#D9D9D9',
  },
  checkbox: {
    label: '#FFFFFF',
    bg: '#3B3B54',
    icon: '#08F9AE',
  },
  signup: {
    title: '#FFFFFF',
    text: '#FFFFFF',
  },
  login: {
    title: '#FFFFFF',
    text: '#FFFFFF',
  },
  profile: {
    title: '#FFFFFF',
    text: '#FFFFFF',
  },
  resetPassword: {
    title: '#FFFFFF',
    text: '#FFFFFF',
  },
  forgotPassword: {
    title: '#FFFFFF',
    text: '#FFFFFF',
    success: '#009656',
  },
  unsubscribe: {
    title: '#FFFFFF',
    text: '#FFFFFF',
    iconButton: '#FFFFFF',
  },
  countrySelect: {
    bg: '#3B3B54',
    border: 'transparent',
    text: '#FFFFFF',
    menuBg: '#3B3B54',
    placeholder: '#8A8AB2',
    arrow: '#8A8AB2',
  },
  sidebar: {
    bg: '#3B3B54',
    close: '#FFFFFF',
    logo: '#FFFFFF',
    text: '#FFFFFF',
    overlay: 'rgba(0, 0, 0, 0.40)',
  },
  button: {
    defaultText: '#FFFFFF',
    primary: {
      bg: 'linear-gradient(251deg, #67F482 -12.7%, #3BBB99 103.67%)',
      disabledBg: 'linear-gradient(180deg, #7BD46D 0%, #529248 100%)',
      text: '#FFFFFF',
      disabledText: 'rgba(7, 60, 28, 0.3)',
    },
    secondary: {
      bg: 'linear-gradient(180deg, #F73535 0%, #CF2A2A 100%)',
      border: '#EE3333',
      disabledBg: 'linear-gradient(180deg, #F73535 0%, #CF2A2A 100%)',
      text: '#FFFFFF',
    },
    outline: {
      border: '#1CFF47',
    },
  },
  modal: {
    bg: '#88B3FF',
    overlayBg: 'rgba(0, 0, 0, 0.40)',
    title: '#111111',
    text: '#FFFFFF',
    closeBtn: '#FFFFFF',
  },
};

export default colors;
