import { useEffect } from 'react';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { RoutesEnum } from '@/enum/routes.enum';
import { SearchParamsEnum } from '@/enum/searchParams.enum';
import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useJWT } from '@/hooks/useJWT';
import { useLocalStorage } from '@/hooks/useLocalStorage';

const AUTH_PAGES: Array<string> = [RoutesEnum.PROFILE];

export const useAuth = () => {
  const { jwt, removeJWT } = useJWT();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { removeItem: removeUserID } = useLocalStorage<string>(
    StorageKeysEnum.user_id,
  );

  const token = searchParams.get(SearchParamsEnum.token);

  const isNeedAuth = AUTH_PAGES.includes(pathname);

  useEffect(() => {
    if (token === '') {
      removeJWT();
      removeUserID();
    }
  }, [removeJWT, removeUserID, token]);
  useEffect(() => {
    if (isNeedAuth && !jwt) navigate(RoutesEnum.HOME);
  });
};
