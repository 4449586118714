import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { useAuth } from '@/hooks/useAuth';
import { useLangDetection } from '@/hooks/useLangDetection';
import { useRedirectUrl } from '@/hooks/useRedirectUrl';

export const AppWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { pathname } = useLocation();

  useAuth();
  useLangDetection();
  useRedirectUrl();

  useEffect(
    () => window.parent.postMessage({ type: 'scrollTop' }, '*'),
    [pathname],
  );

  return <>{children}</>;
};
