import { Helmet, HelmetProvider as Provider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { tKeys } from '@/localization';

export const MetadataProvider = () => {
  const { t } = useTranslation();
  return (
    <Provider>
      <Helmet>
        <title>{t(tKeys.gamezone)}</title>
        <link href='https://fonts.googleapis.com' rel='preconnect' />
        <link href='https://fonts.gstatic.com' rel='preconnect' />
        <link
          href='https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap'
          rel='stylesheet'
        />
      </Helmet>
    </Provider>
  );
};
