import { ComponentStyleConfig } from '@chakra-ui/react';

const Checkbox: ComponentStyleConfig = {
  sizes: {
    md: {
      control: {
        width: '26px',
        height: '26px',
      },
      label: {
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '140%',
      },
    },
  },
  baseStyle: {
    label: {
      color: 'checkbox.label',
    },
    control: {
      background: 'checkbox.bg',
      borderRadius: '10px',
      borderColor: 'transparent',

      _checked: {
        background: 'checkbox.bg',
        color: 'checkbox.icon',
        border: 'none',

        _hover: {
          background: 'checkbox.bg',
          border: 'none',
        },
      },
    },
  },
};

export default Checkbox;
