import React, { useMemo } from 'react';

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Link as ChakraLink,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  Link as ReactRouterLink,
  matchPath,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { enabledLanguages, tKeys } from '@/localization';

import { UserIcon } from '@/assets/icons/UserIcon';
import OlybetLogo from '@/assets/images/olybet-logo.svg';

import { useSidebarContext } from '@/context/sidebar.context';
import { useUserContext } from '@/context/user.context';

import LangSwitcher from '@/elements/LangSwitcher';

import { RoutesEnum } from '@/enum/routes.enum';
import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useNavigations } from '@/hooks/useNavigations';

import { triggerGTAGEvent } from '@/utils/analyticTracks';
import { HIDE_PROFILE } from '@/utils/constants';

export const Sidebar: React.FC = () => {
  const { isSidebarOpen, onSidebarClose } = useSidebarContext();
  const theme = useTheme();
  const navigate = useNavigate();
  const navigations = useNavigations();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { user } = useUserContext();
  const { value: jwt, removeItem: removeJWT } = useLocalStorage(
    StorageKeysEnum.jwt,
  );
  const { removeItem: removeUserID } = useLocalStorage(StorageKeysEnum.user_id);

  const goToSignup = () => {
    triggerGTAGEvent('signup.step1');
    navigate(RoutesEnum.REGISTRATION);
  };

  const goToLogin = () => {
    navigate(RoutesEnum.LOGIN);
  };

  const goToHome = () => {
    onSidebarClose();
    navigate(RoutesEnum.HOME);
  };

  const handleLogout = () => {
    removeJWT();
    removeUserID();
  };

  const checkIsPath = (path: string) => Boolean(matchPath(pathname, path));

  const isLangSwitcherEnabled = useMemo(() => enabledLanguages.length > 1, []);

  return (
    <Drawer isOpen={isSidebarOpen} onClose={onSidebarClose} placement='left'>
      <DrawerOverlay backdropFilter='blur(8px)' />
      <DrawerContent backgroundColor={theme.colors.sidebar.bg} maxW='250px'>
        <DrawerHeader pt='50px'>
          <Button h='76px' mx='auto' onClick={goToHome} p='0' variant='ghost'>
            <Image alt='' h='100%' src={OlybetLogo} w='100%' />
          </Button>
        </DrawerHeader>

        <DrawerBody
          display='flex'
          flexDirection='column'
          gap='4px'
          mt='64px'
          p='0'
        >
          {navigations.map(({ icon, label, link }, idx) => (
            <ChakraLink
              _hover={{ textDecoration: 'none' }}
              as={ReactRouterLink}
              key={idx}
              to={link}
            >
              <Flex
                _hover={{ color: !checkIsPath(link) && 'navbar.selected' }}
                align='center'
                bg={checkIsPath(link) ? 'navbar.selected' : ''}
                color='navbar.text'
                gap='24px'
                p='16px'
                position='relative'
                w='100%'
              >
                <Image as={icon} />
                <Text
                  fontSize='16px'
                  fontWeight='500'
                  lineHeight='1.4'
                  textTransform='capitalize'
                >
                  {label}
                </Text>
              </Flex>
            </ChakraLink>
          ))}
        </DrawerBody>

        <DrawerFooter alignItems='center' flexDirection='column' gap='24px'>
          <>
            {isLangSwitcherEnabled && <LangSwitcher />}
            {user && !HIDE_PROFILE && (
              <Button
                alignItems='center'
                color={theme.colors.sidebar.text}
                display='flex'
                onClick={() => navigate(RoutesEnum.PROFILE)}
                rightIcon={<UserIcon />}
                variant='unstyled'
                w='fit-content'
              >
                <Text noOfLines={1}>{user.username}</Text>
              </Button>
            )}
            {!jwt && (
              <Button onClick={goToSignup} variant='primary'>
                {t(tKeys.joinNow)}
              </Button>
            )}
            {!jwt && (
              <Button onClick={goToLogin} variant='secondary'>
                {t(tKeys.login)}
              </Button>
            )}
            {jwt && (
              <Button
                h='48px'
                onClick={handleLogout}
                variant='outline'
                w='100%'
              >
                {t(tKeys.logout)}
              </Button>
            )}
          </>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
