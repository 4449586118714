import { extendTheme } from '@chakra-ui/react';
import { Styles } from '@chakra-ui/theme-tools';

import breakpoints from '@/theme/foundations/breakpoints';
import colors from '@/theme/foundations/colors';
import components from '@/theme/foundations/components';
import fonts from '@/theme/foundations/fonts';
import radius from '@/theme/foundations/radius';
import shadows from '@/theme/foundations/shadows';
import space from '@/theme/foundations/space';

const styles: Styles = {
  global: () => ({
    'html, body, #root': {
      width: '100%',
    },

    body: {
      backgroundColor: 'var(--chakra-colors-appBg-primaryBg)',
    },

    '*': {
      boxSizing: 'border-box',
    },
  }),
};

const theme = extendTheme({
  fonts,
  colors,
  breakpoints,
  space,
  styles,
  shadows,
  radii: radius,
  components,
});

export default theme;
