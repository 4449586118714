import React, { useMemo, useState } from 'react';

import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Text,
  useTheme,
  useMediaQuery,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft } from 'react-icons/fa6';
import { useLocation, useNavigate } from 'react-router-dom';

import { enabledLanguages, tKeys } from '@/localization';

import { UserIcon } from '@/assets/icons/UserIcon';
import Burger from '@/assets/images/OlyBet/icon/burger.svg';
import OlybetLogo from '@/assets/images/olybet-logo.svg';

import ArrowButton from '@/components/Button/ArrowButton';

import { useSidebarContext } from '@/context/sidebar.context';
import { useUserContext } from '@/context/user.context';

import LangSwitcher from '@/elements/LangSwitcher';

import { MediaQueriesEnum } from '@/enum/mediaQueries.enum';
import { RoutesEnum } from '@/enum/routes.enum';
import { StorageKeysEnum } from '@/enum/storageKeys.enum';

import { useLocalStorage } from '@/hooks/useLocalStorage';

import { triggerGTAGEvent } from '@/utils/analyticTracks';
import { HIDE_PROFILE } from '@/utils/constants';

const Header: React.FC = () => {
  const [isDesktop] = useMediaQuery(MediaQueriesEnum.isDesktop, { ssr: false });
  const { onSidebarOpen } = useSidebarContext();
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { user } = useUserContext();
  const { value: jwt, removeItem: removeJWT } = useLocalStorage<string>(
    StorageKeysEnum.jwt,
  );
  const { removeItem: removeUserID } = useLocalStorage<string>(
    StorageKeysEnum.user_id,
  );

  const [logoLoaded, setLogoLoaded] = useState(false);

  const isLangSwitcherEnabled = useMemo(() => enabledLanguages.length > 1, []);

  const goToSignup = () => {
    triggerGTAGEvent('signup.step1');
    navigate(RoutesEnum.REGISTRATION);
  };

  const goToLogin = () => {
    navigate(RoutesEnum.LOGIN);
  };

  const goToHome = () => {
    navigate(RoutesEnum.HOME);
  };

  const handleLogout = () => {
    removeJWT();
    removeUserID();
    navigate(RoutesEnum.HOME);
  };

  const isHeaderShown = useMemo(
    () => pathname !== RoutesEnum.SSOENTRY,
    [pathname],
  );

  const isBackBtnVisible = useMemo(
    () =>
      [RoutesEnum.LOGIN, RoutesEnum.REGISTRATION].some(
        (route) => route === pathname,
      ),
    [pathname],
  );

  const backBtnContext = useMemo(
    () =>
      pathname === RoutesEnum.REGISTRATION
        ? { text: t(tKeys.returnToLogin), route: RoutesEnum.LOGIN }
        : { text: t(tKeys.returnToGamezone), route: RoutesEnum.HOME },
    [pathname, t],
  );

  return (
    <Box
      as='header'
      background={theme.colors.header.primaryBg}
      borderBottom='0.1px solid'
      borderColor={theme.colors.header.border}
      display={isHeaderShown ? 'block' : 'none'}
      h='84px'
      position='fixed'
      px='20px'
      py='0'
      top='0'
      w='100%'
      zIndex='997'
    >
      <Grid
        alignItems='center'
        h='100%'
        templateColumns='1fr minmax(160px, 1fr) 1fr'
        w='100%'
      >
        {isDesktop && isBackBtnVisible && (
          <ArrowButton
            fontSize='14px'
            justifySelf='start'
            leftIcon={<FaArrowLeft />}
            onClick={() => navigate(backBtnContext.route)}
          >
            {backBtnContext.text}
          </ArrowButton>
        )}

        {!isDesktop && (
          <Image
            alt='menu-icon'
            cursor='pointer'
            onClick={onSidebarOpen}
            src={Burger}
          />
        )}

        <Button gridColumn={2} onClick={goToHome} p='0' variant='ghost'>
          <Image
            alt=''
            display={logoLoaded ? 'block' : 'none'}
            onLoad={() => setLogoLoaded(true)}
            src={OlybetLogo}
          />
        </Button>

        {isDesktop && (
          <Flex align='center' gap='10px' justify='flex-end' w='100%'>
            {user && !HIDE_PROFILE && (
              <Button
                alignItems='center'
                color={theme.colors.header.text}
                display='flex'
                fontSize='17px'
                fontWeight='700'
                justifyContent='center'
                leftIcon={<UserIcon />}
                onClick={() => navigate(RoutesEnum.PROFILE)}
                px='5px'
                variant='unstyled'
                w='150px'
              >
                <Text noOfLines={1}>{user.username}</Text>
              </Button>
            )}
            {!jwt && (
              <Button onClick={goToSignup} size='md' variant='primary'>
                {t(tKeys.joinNow)}
              </Button>
            )}
            {!jwt && (
              <Button onClick={goToLogin} size='sm' variant='ghost'>
                {t(tKeys.login)}
              </Button>
            )}
            {jwt && (
              <Button onClick={handleLogout} size='sm' variant='ghost'>
                {t(tKeys.logout)}
              </Button>
            )}
            {isLangSwitcherEnabled && <LangSwitcher />}
          </Flex>
        )}
      </Grid>
    </Box>
  );
};

export default Header;
