import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  header: {
    fontFamily: "'Bebas Neue', sans-serif",
    color: 'modal.title',
    textTransform: 'uppercase',
    textAlign: 'center',
    pt: '60px',
    lineHeight: '130%',
    pb: '16px',
  },
  body: {
    pt: '0',
    textAlign: 'center',
    color: 'modal.text',
    fontWeight: '700',
  },
  footer: {
    justifyContent: 'center',
    pt: '30px',
  },
  overlay: {
    bg: 'modal.overlayBg',
    backdropFilter: 'blur(8px)',
  },
  dialog: {
    borderRadius: '16px',
    bg: 'modal.bg',
    mx: '16px',
  },
});

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
});
