import { ComponentStyleConfig } from '@chakra-ui/react';

const Input: ComponentStyleConfig = {
  baseStyle: {
    element: {
      right: '12px',
    },
  },
  variants: {
    primary: {
      field: {
        width: '100%',
        borderRadius: '8px',
        background: 'input.bg',
        padding: '8px 12px 8px 16px',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '140%',
        color: 'input.text',
        _placeholder: {
          color: 'input.placeholder',
        },
        _focus: {
          border: '2px solid',
          borderColor: 'input.border',
        },
        _invalid: {
          borderColor: 'input.invalid',
          boxShadow: 'none',
        },
      },
    },
    readonly: {
      field: {
        backgroundColor: 'input.placeholder',
      },
    },
  },
};

export default Input;
